export enum PhaseState {
  PENDING,
  INACTIVE,
  SUCCESS,
  ERROR,
}

export const PhaseColor: { [state: string]: string } = {
  [PhaseState.PENDING]: "bg-green",
  [PhaseState.SUCCESS]: "bg-green",
  [PhaseState.INACTIVE]: "bg-gray-200",
  [PhaseState.ERROR]: "bg-red",
};
