import config from "@shared/config/client";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import queryString from "query-string";
import { User } from "@/api/users/types";
import { CompletedQuestDto, QuestDto } from "./types";

const getQuestsLeaders =
  (request: ICallableRequestBuilder<User[]>) =>
  (userId: string, take = DEFAULT_PAGE_SIZE, skip = 0): Promise<User[]> => {
    const query = {
      take,
      skip,
    };
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/quests/leaders/${userId}`,
      query,
    });

    return request.call(url);
  };

const getCompletedQuestsCount =
  (request: ICallableRequestBuilder<number>) => (userId: string) => {
    return request.call(`${config.apiUrl}/quests/completed/count/${userId}`);
  };

const getTotalQuestsCount =
  (request: ICallableRequestBuilder<number>) => (userId: string) => {
    return request.call(`${config.apiUrl}/quests/total/count/${userId}`);
  };

export const getMyCompletedQuests =
  (request: ICallableRequestBuilder<CompletedQuestDto[]>) =>
  async (take = DEFAULT_PAGE_SIZE, skip = 0) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/quests/completed`,
      query: {
        take,
        skip,
      },
    });

    return request.call(url);
  };

export const getMyUncompletedQuests =
  (request: ICallableRequestBuilder<QuestDto[]>) =>
  async (take = DEFAULT_PAGE_SIZE, skip = 0) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/quests/uncompleted`,
      query: {
        take,
        skip,
      },
    });

    return request.call(url);
  };

const getFirstUncompletedQuest =
  (request: ICallableRequestBuilder<QuestDto>) => (userId: string) => {
    return request.call(`${config.apiUrl}/quests/first-uncompleted/${userId}`);
  };

const requests = {
  getQuestsLeaders,
  getCompletedQuestsCount,
  getTotalQuestsCount,
  getMyCompletedQuests,
  getMyUncompletedQuests,
  getFirstUncompletedQuest,
};

export default requests;
