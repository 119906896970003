import { useEffect, useRef } from "react";

function useOutsideClick<T extends HTMLElement = HTMLElement>({
  handler,
  enabled,
}: {
  handler: () => void;
  enabled: boolean;
}) {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [handler, enabled]);

  return ref;
}

export default useOutsideClick;
