import config from "../client";
import { Blockchain } from "./types";

const getCurrentChain = (cachedChain: string | undefined) => {
  const availableChains = config.availableChains.split(",");

  let currentChain;

  if (cachedChain && availableChains.includes(cachedChain)) {
    currentChain = cachedChain;
  } else {
    currentChain = config.defaultChain;
  }

  return currentChain as Blockchain;
};

const getChainConfig = (chainId: number | null | undefined) => {
  if (!chainId) return;

  const blockchainConfigs = config.chains;

  for (const chainName in blockchainConfigs) {
    const chainConfig = blockchainConfigs[chainName];

    if (chainConfig.id === +chainId) {
      return chainConfig;
    }
  }
};

const getChainContracts = (chainId: number | null | undefined) => {
  const chainConfig = getChainConfig(chainId);

  return chainConfig?.contracts;
};

const getPaymentMethods = (chainId: number | null | undefined) => {
  const chainConfig = getChainConfig(chainId);
  return chainConfig?.paymentMethods;
};
const getNetworkPaymentMethods = (network: Blockchain | null) => {
  const chainConfig = resolveChainConfig(network);
  return chainConfig?.paymentMethods;
};

const getPaymentMethod = (
  chainId: number | null | undefined,
  paymentMethodAddress: string,
) => {
  const paymentMethods = getPaymentMethods(chainId);

  if (!paymentMethods) {
    return;
  }

  return paymentMethods[paymentMethodAddress];
};

const resolveChainConfig = (chainName: string | null | undefined) => {
  if (!chainName) {
    return;
  }

  const chainConfig = config.chains[chainName];

  if (!chainConfig) {
    throw new Error("Unsupported chain config!");
  }

  return chainConfig;
};

const resolveChainID = (chainName: string | null | undefined) => {
  const chainConfig = resolveChainConfig(chainName);

  if (!chainConfig) {
    return;
  }

  return +chainConfig?.id;
};

const resolveChainRPCUrl = (chainName: string | null | undefined) => {
  const chainConfig = resolveChainConfig(chainName);

  if (!chainConfig) {
    return;
  }

  return chainConfig?.rpcUrl;
};

export {
  getChainConfig,
  getNetworkPaymentMethods,
  getCurrentChain,
  getChainContracts,
  getPaymentMethods,
  getPaymentMethod,
  resolveChainConfig,
  resolveChainID,
  resolveChainRPCUrl,
};
