import Link from "next/link";
import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  href: string;
  target?: string;
  wrapWithLink: boolean;
  componentType?: "Link" | "a";
  className?: string;
  onClick?: () => void;
}

const DynamicLinkWrapper = ({
  children,
  href,
  onClick,
  wrapWithLink,
  target,
  componentType = "Link",
  className,
}: Props) => {
  if (!wrapWithLink) return <span className={className}>{children}</span>;

  if (componentType === "Link") {
    return (
      <Link
        onClick={onClick}
        className={twMerge("cursor-pointer", className)}
        href={href}
        target={target}
      >
        {children}
      </Link>
    );
  }

  if (componentType === "a") {
    return (
      <a
        href={href}
        target={target}
        onClick={onClick}
        className={twMerge("cursor-pointer", className)}
      >
        {children}
      </a>
    );
  }
};

export default DynamicLinkWrapper;
