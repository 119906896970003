import { Modal } from "@/app/(components)/Basic/Modal";
import { handleCopyText } from "@/shared/utilities";
import { useState } from "react";
import config from "@/shared/config/client";
import { Button } from "@/app/(components)/Basic/Button";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  referralCode: string;
}

const ReferralModal = ({ isOpen, setIsOpen, referralCode }: Props) => {
  const [disabled, setDisabled] = useState(false);

  const copyReferralLink = () => {
    if (disabled) return;
    handleCopyText(
      `${config.url}/login?referralCode=${referralCode}`,
      setDisabled,
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} hasCloseButton>
      <h3 className="mb-6 text-center">Invite others</h3>
      <div className="text-body-2 mb-10">
        Use this copy and send link to others. If they create account
        <br />
        with your link you will earn 30000 points.
      </div>
      <Button
        onClick={copyReferralLink}
        className="mx-auto block"
        disabled={disabled}
      >
        {!disabled ? "Copy invite link" : "Copied"}
      </Button>
    </Modal>
  );
};

export default ReferralModal;
