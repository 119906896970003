import config from "@shared/config/client";
import { enrichItem, exportAddressesByProperties } from "../helpers";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import {
  withAuthenticatedClientRequest,
  withClientRequest,
} from "../requestBuilder/withClientRequest";
import { EnrichedObject, EnrichmentProperties } from "../types";
import requests from "./requests";
import { User, UserUpdate } from "./types";

const updateMyProfile =
  (request: ICallableRequestBuilder<UserUpdate>) => (user: UserUpdate) => {
    return request.call(`${config.apiUrl}/users`, (init) => ({
      ...init,
      method: "PUT",
      headers: {
        ...init.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: user.id,
        address: user.address,
        displayName: user.displayName,
        fullName: user.fullName,
        imageUrl: user.imageUrl,
        imagePlaceholder: user.imagePlaceholder,
        headerImagePlaceholder: user.headerImagePlaceholder,
        twitter: user.twitter,
        telegram: user.telegram,
        instagram: user.instagram,
        facebook: user.facebook,
        website: user.website,
        discord: user.discord,
        homeAddress: user.homeAddress,
        description: user.description,
        clothingSize: user.clothingSize,
        headerImageUrl: user.headerImageUrl,
        email: user.email,
        hideProfile: user.hideProfile,
        openToWork: user.openToWork,
        receiveTradeNotifications: user.receiveTradeNotifications,
        receiveNftNotifications: user.receiveNftNotifications,
        receivePostNotifications: user.receivePostNotifications,
        receiveFollowedNotification: user.receiveFollowedNotification,
        receiveTipReceivedNotification: user.receiveTipReceivedNotification,
        receiveNftReadyToMintNotification:
          user.receiveNftReadyToMintNotification,
        receiveNftDeniedNotification: user.receiveNftDeniedNotification,
      }),
    }));
  };

export async function enrichWithUser<
  T extends { [k: string]: any },
  TPropsToEnrich extends string,
>(array: T[], properties: EnrichmentProperties<T>) {
  const listOfAddresses = exportAddressesByProperties(array, properties);
  const users = await withClientRequest(requests.getUsersByIdentifiers)(
    listOfAddresses,
  );
  const newArray = array.map((item) => {
    const newItem: any = enrichItem(
      item,
      properties,
      users!,
      (user) => user.address!,
    );
    return newItem;
  });

  return newArray as EnrichedObject<T, TPropsToEnrich, User>[];
}

const disconnectTwitter = (request: ICallableRequestBuilder<void>) => () => {
  return request.call(`${config.apiUrl}/users/twitter/disconnect`, (init) => ({
    ...init,
    method: "PUT",
  }));
};

const disconnectDiscord = (request: ICallableRequestBuilder<void>) => () => {
  return request.call(`${config.apiUrl}/users/discord/disconnect`, (init) => ({
    ...init,
    method: "PUT",
  }));
};

const disconnectTelegram = (request: ICallableRequestBuilder<void>) => () => {
  return request.call(`${config.apiUrl}/users/telegram/disconnect`, (init) => ({
    ...init,
    method: "PUT",
  }));
};

const clientRequsts = {
  getFeaturedArtists: withClientRequest(requests.getFeaturedArtists),
  getArtists: withClientRequest(requests.getArtists),
  getUserByIdentifier: withClientRequest(requests.getUserByIdentifier),
  getUsersByIdentifiers: withClientRequest(requests.getUsersByIdentifiers),
  getFollowingById: withClientRequest(requests.getFollowingById),
  getFollowersById: withClientRequest(requests.getFollowersById),
  me: withAuthenticatedClientRequest(requests.me),
  updateMyProfile: withAuthenticatedClientRequest(updateMyProfile),
  sendVerificationEmail: withAuthenticatedClientRequest(
    requests.sendVerificationEmail,
  ),
  enrichWithUser,
  disconnectDiscord: withAuthenticatedClientRequest(disconnectDiscord),
  disconnectTelegram: withAuthenticatedClientRequest(disconnectTelegram),
  disconnectTwitter: withAuthenticatedClientRequest(disconnectTwitter),
};

export default clientRequsts;
