import Cookies from "js-cookie";
import config from "@shared/config/client";
import { RequestBuilder } from "./requestBuilder";

export class ClientRequestBuilder<T> extends RequestBuilder<T> {
  constructor(url?: string, signal?: AbortSignal);
  constructor(
    callback: (requestInit: RequestInit) => Promise<T>,
    signal?: AbortSignal,
  );

  constructor(
    arg: string | ((requestInit: RequestInit) => Promise<T>) | undefined,
    signal?: AbortSignal,
  ) {
    super(arg, signal);
  }

  public async authenticate() {
    this.requestInit = {
      ...this.requestInit,
      credentials: "include",
    };

    return this;
  }

  public async getBlockchain(): Promise<string | undefined> {
    return Cookies.get(config.cookies.blockchain);
  }
}
