import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { PhaseColor, PhaseState } from "./constants";

export interface PhaseType {
  id: number;
  description: string;
}

export interface PhaseStateType {
  [id: number]: number;
}

export interface Props {
  phases: PhaseType[];
  phaseState: PhaseStateType;
}

const PhaseBar = ({ phaseState, phases }: Props) => {
  const phaseElements = useMemo(() => {
    let text = "";
    const elements = phases.map((phase, index) => {
      const isPendingPhase = phaseState[phase.id] === PhaseState.PENDING;
      if (isPendingPhase) text = phase.description;
      return (
        <div
          key={index}
          className={twMerge(
            `w-full h-1 
              ${isPendingPhase ? "animate-pulse" : ""} 
              ${PhaseColor[phaseState[phase.id]]}`,
          )}
        />
      );
    });
    return (
      <>
        <div className="flex flex-row gap-x-2 w-full">{elements}</div>
        <p className="text-body-4 mt-2 text-center w-full">{text}</p>
      </>
    );
  }, [phaseState, phases]);

  return <div className="w-full">{phaseElements}</div>;
};

export default PhaseBar;
