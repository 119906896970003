import { twMerge } from "tailwind-merge";
import { ThemedIcon } from "../Icon";

export interface Props {
  message: string;
  className?: string;
}

export default function NoItems({ message, className }: Props) {
  return (
    <div
      className={twMerge("flex flex-col w-full items-center mt-20", className)}
    >
      <ThemedIcon className="w-20 h-20" iconName="endyV2" />
      <div className="py-10 text-body-2 text-center text-primary">
        <span className="text-body-3 lg:text-body-2">{message}</span>
      </div>
    </div>
  );
}
