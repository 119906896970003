import { Modal } from "@/app/(components)/Basic/Modal";
import { PhaseBar } from "@/app/(components)/Basic/PhaseBar";
import EndyGreenIcon from "@/assets/icons/endy/endy-green.svg";
import { PhaseStateType } from "../PhaseBar/PhaseBar";

interface PhaseType {
  id: number;
  description: string;
}

export interface Props {
  text: string;
  isOpen: boolean;
  handleClose: () => void;
  phaseState: PhaseStateType;
  phases: PhaseType[];
  error?: string;
  redirectUrl?: string;
}

export default function ProgressModal({
  text,
  isOpen,
  handleClose,
  phaseState,
  phases,
  error,
}: Props) {
  return (
    <Modal
      hasCloseButton={!!error}
      className="flex flex-col items-center gap-y-6 w-full max-w-[510px]"
      isOpen={isOpen}
      onClose={handleClose}
      disableClose={!error}
    >
      <EndyGreenIcon className="w-15 h-15 night:fill-white fill-black" />
      <div className="text-h2-sm">Please wait...</div>
      <div className="text-body-2">{text}</div>
      <PhaseBar phaseState={phaseState} phases={phases} />
    </Modal>
  );
}
