import { QuestClasses } from "./types";

export const bgClasses: QuestClasses = {
  red: "bg-red",
  yellow: "bg-yellow",
  green: "bg-green",
  gray: "bg-endemicGray-500",
};

export const iconHoverClasses: QuestClasses = {
  red: "group-hover:fill-red",
  yellow: "group-hover:fill-yellow",
  green: "group-hover:fill-green",
  gray: "",
};

export const iconClasses: QuestClasses = {
  red: "fill-red",
  yellow: "fill-yellow",
  green: "fill-green",
};

export const textHoverClasses: QuestClasses = {
  red: "group-hover:text-red",
  yellow: "group-hover:text-yellow",
  green: "group-hover:text-green",
  gray: "",
};

export const textClasses: QuestClasses = {
  red: "text-red",
  yellow: "text-yellow",
  green: "text-green",
  gray: "text-endemicGray-500",
};

export const questWrapperClasses: QuestClasses = {
  red: "border-red hover:bg-red",
  yellow: "border-yellow hover:bg-yellow",
  green: "border-green hover:bg-green",
  gray: "border-endemicGray-500 hover:bg-endemicGray-500",
};

export const questBorderClasses: QuestClasses = {
  red: "border-red",
  yellow: "border-yellow",
  green: "border-green",
};
