import { uniq } from "lodash";
import { EnrichmentProperties } from "./types";

/* ENRICHMENT HELPERS */

export function getAddressForEnrichment<T extends { [k: string]: any }>(
  item: T,
  key: string,
  properties: EnrichmentProperties<T>,
) {
  const propertyOption = properties[key];
  if (typeof propertyOption === "boolean") {
    return item[key] as string;
  } else if (typeof propertyOption === "object") {
    return propertyOption.addressGetter(item);
  } else {
    throw Error("wrong address");
  }
}

export function exportAddressesByProperties<T extends { [k: string]: any }>(
  array: T[],
  properties: EnrichmentProperties<T>,
) {
  const list = array.reduce((sum: string[], item) => {
    const addresses: string[] = Object.keys(item)
      .filter((key) => !!properties[key])
      .map((key) => getAddressForEnrichment(item, key, properties));

    return [...sum, ...addresses];
  }, []);

  return uniq(list);
}

export function enrichItem<T extends { [k: string]: any }, TEnricher>(
  item: T,
  properties: EnrichmentProperties<T>,
  fetchedEnrichers: TEnricher[],
  getEnricherId: (enricher: TEnricher) => string,
) {
  const newItem: any = Object.keys(item).reduce((newItem, key) => {
    const propertyOptions = properties[key];

    if (!!propertyOptions) {
      const address = getAddressForEnrichment<T>(item, key, properties);
      let newKey = key;

      if (typeof propertyOptions === "object") {
        newKey = propertyOptions.name || key;
      }

      const enrichedItem = fetchedEnrichers.find(
        (enricher) => getEnricherId(enricher) === address?.toLowerCase(),
      );

      return {
        ...newItem,
        [key]: item[key],
        [newKey]: key === newKey ? enrichedItem || item[key] : enrichedItem,
      };
    }

    return {
      ...newItem,
      [key]: item[key],
    };
  }, {});

  return newItem;
}

/* FORM DATA HELPERS */
export default function buildFormData(file: File) {
  const formData = new FormData();

  formData.append("file", file);

  return formData;
}
