export const fetchApi = async (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
) => {
  const res = await fetch(input, init);

  if (res.status >= 400) {
    const { message } = await res.json();
    throw Error(message);
  }

  return res;
};
