"use client";
import { ProgressModal } from "@/app/(components)/Basic/ProgressModal";
import { useProgressContext } from "@/app/(components)/Basic/ProgressModal/context/context";
import mintdashApi from "@/api/mintdash/client";
import { useWeb3State } from "@/web3/context";
import { useCallback, useEffect } from "react";
import { PhaseState } from "@/app/(components)/Basic/PhaseBar/constants";
import { CLAIM_INITIAL_STATE, ClaimPhase } from "./constants";
import useMe from "@/shared/hooks/useMe";
import { createFreeClaimMessageToSign } from "../../Settings/SettingsContent/helpers";
import { PhaseStateType } from "@/app/(components)/Basic/PhaseBar/PhaseBar";
import { useQueryClient } from "@tanstack/react-query";

export interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

export default function ClaimTokenModal({ onClose, onSuccess }: Props) {
  const { isOpen, setIsOpen, phaseState, setPhaseState, error, handleError } =
    useProgressContext();
  const queryClient = useQueryClient();
  const { me } = useMe();
  const { signer } = useWeb3State();

  useEffect(() => {
    if (setPhaseState) {
      setPhaseState(CLAIM_INITIAL_STATE);
    }
  }, [setPhaseState]);

  const checkClaimStatus = async (jobId: number) => {
    const promise = new Promise((resolve, reject) => {
      let retry = 0;
      const intervalId = setInterval(async (): Promise<void> => {
        const { state } = await mintdashApi.checkClaimStatus(+jobId);

        if (state === "completed") {
          clearInterval(intervalId);
          resolve(state);
          return;
        } else if (retry > 20) {
          clearInterval(intervalId);
          reject(state);
          return;
        } else {
          retry++;
          return;
        }
      }, 2000);
    });

    return promise;
  };

  const claimEndemicIdentity = useCallback(async () => {
    try {
      if (me && me.address) {
        const listOfNfts = await mintdashApi.getListOfCollectionNfts(
          me.address,
        );
        setPhaseState(() => ({
          [ClaimPhase.CHECK]: PhaseState.SUCCESS,
          [ClaimPhase.CLAIM]: PhaseState.PENDING,
        }));

        if (listOfNfts.length) {
          await mintdashApi.mint({
            owner: me.address,
            nfts: listOfNfts,
          });
        } else {
          const signature = await signer!.signMessage(
            createFreeClaimMessageToSign(me!.address!),
          );

          const result = await mintdashApi.claimIdenityToken(
            me!.address!,
            signature,
          );
          const { jobId } = result;
          await checkClaimStatus(+jobId);
        }

        setPhaseState((state: PhaseStateType) => ({
          ...state,
          [ClaimPhase.CLAIM]: PhaseState.SUCCESS,
        }));

        await queryClient.invalidateQueries(["me"]);
        onSuccess();
      }
    } catch {
      handleError(ClaimPhase.CHECK, "Claim unsuccessfull");
    }
  }, [me, queryClient, signer, handleError, onSuccess, setPhaseState]);

  useEffect(() => {
    if (phaseState[ClaimPhase.CHECK] !== PhaseState.PENDING) {
      return;
    }
    setIsOpen(true);
    claimEndemicIdentity();
  }, [phaseState, setIsOpen, claimEndemicIdentity]);

  return (
    <ProgressModal
      text="Your token is being claimed"
      isOpen={!!isOpen}
      handleClose={onClose}
      phaseState={phaseState}
      phases={[
        {
          id: ClaimPhase.CHECK,
          description: "Claiming token",
        },
        {
          id: ClaimPhase.CLAIM,
          description: "Token has been claimed",
        },
      ]}
      error={error}
    />
  );
}
