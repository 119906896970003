import { PhaseState } from "@/app/(components)/Basic/PhaseBar/constants";

export enum ClaimPhase {
  CHECK,
  CLAIM,
}

export const CLAIM_INITIAL_STATE = {
  [ClaimPhase.CHECK]: PhaseState.PENDING,
};
