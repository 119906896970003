import { Web3ContextActions, Web3ContextState } from "./types";
import { empty, emptyAsync } from "@/shared/helpers";
import config from "@/shared/config/client";
import { resolveChainID } from "@/shared/config/blockchain/helpers";
import { Blockchain } from "@/shared/config/blockchain/types";

export const CACHED_CONNECTOR_ID_KEY = "CACHED_CONNECTOR_ID";

export const initialState: Web3ContextState = {
  web3: null,
  currentAddress: null,
  network: config.defaultChain as Blockchain,
  chainId: resolveChainID(config.defaultChain),
  signer: null,
  isCorrectChain: false,
  isWalletPickerOpen: false,
  isAccountChangedPopupVisible: false,
  isNetworkMismatchPopupVisible: false,
  readOnlyProvider: null,
  onSuccessfulNetworkChange: empty,
};

async function connectToEthereum() {
  return undefined;
}

export const initialActions: Web3ContextActions = {
  connectToEthereum,
  disconnect: empty,
  switchChain: emptyAsync,
  addChain: emptyAsync,
  toggleWalletPicker: empty,
  toggleNetworkMismatch: empty,
  hideAccountChangePopup: empty,
  switchBlockchain: empty,
};
