// eslint-disable-next-line
export const empty = () => {};
export const emptyAsync = async () => {};

type RenameMap<T> = Partial<Record<keyof T, string>>;

type RenameKeys<T, R extends RenameMap<T>> = {
  [K in keyof T as K extends keyof R ? R[K] & string : K]: T[K];
};

export function buildObject<
  T extends object,
  R extends RenameMap<T>,
  Res extends RenameKeys<T, R>,
  K extends keyof Partial<Res>,
>(obj: T, renameMap: R, keysToPick: K[]): Pick<Res, K> {
  type KeysOfSourceObject = keyof T;

  const filtered = Object.entries(obj).reduce(
    (result, [key, value]) => {
      const newKey = (renameMap[key as KeysOfSourceObject] || key) as K;
      if (keysToPick.includes(newKey)) return { ...result, [newKey]: value };
      return result;
    },
    {} as Pick<Res, K>,
  );

  return filtered;
}

export function truncateText(input: string, charLimit: number): string {
  if (input.length <= charLimit) {
    return input;
  }

  const lastSpace = input.lastIndexOf(" ", charLimit);

  return lastSpace > 0
    ? `${input.substring(0, lastSpace)}...`
    : `${input.substring(0, charLimit)}...`;
}

export function formatAddress(address: string): string {
  return address.slice(0, 6) + "..." + address.slice(-4);
}

export function capitalizeFirstLetter(str: string): string {
  if (!str || typeof str !== "string") {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stripHtml(html: string): string {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function getTimestampFromDatePlusTimeFrame(
  date: Date,
  timeframe: number,
) {
  const dateForConvert = new Date();

  dateForConvert.setDate(date.getDate() + timeframe);

  return dateForConvert.getTime();
}

function getLevelMaxScore(level: number): number {
  if (level === 1) {
    return 100000;
  } else {
    return getLevelScore(level) + getLevelMaxScore(level - 1);
  }
}

function getLevelScore(level: number) {
  return (level > 5 ? 5 : level) * 100000;
}

export function getLevelCompletionPercentage(
  currentLevel: number,
  score: number,
) {
  const previousLevel = currentLevel - 1;
  const pointsReceivedForThisLevel =
    score - (previousLevel === 0 ? 0 : getLevelMaxScore(previousLevel));
  return (pointsReceivedForThisLevel / getLevelScore(currentLevel)) * 100;
}

export function getPointsLeftToFinishCurrentLevel(
  currentLevel: number,
  score: number,
) {
  return getLevelMaxScore(currentLevel) - score;
}
