import { User } from "../users/types";

export enum QuestType {
  PROFILE_IMAGE = "PROFILE_IMAGE",
  COVER_IMAGE = "COVER_IMAGE",
  USERNAME = "USERNAME",
  PUBLIC_PROFILE = "PUBLIC_PROFILE",
  TWITTER_FOLLOW = "TWITTER_FOLLOW",
  INSTAGRAM = "INSTAGRAM",
  TELEGRAM_JOIN = "TELEGRAM_JOIN",
  SHARE_UPDATE = "SHARE_UPDATE",
  FOLLOW_ARTIST = "FOLLOW_ARTIST",
  CONNECT_SOCIAL_MEDIA = "CONNECT_SOCIAL_MEDIA",
  SOULBOUND_TOKEN = "SOULBOUND_TOKEN",
  TWITTER_SHARE = "TWITTER_SHARE",
  TWITTER_SHARE_NEWS = "TWITTER_SHARE_NEWS",
  SHORT_BIO = "SHORT_BIO",
  PARTICIPATE_FORUM_DICSUSSION = "PARTICIPATE_FORUM_DISCUSSION",
  ARTWORK_LIKE = "ARTWORK_LIKE",
  ARTWORK_COMMENT = "ARTWORK_COMMENT",
  CLICK_LINK = "CLICK_LINK",
  SHARE_ARTWORK = "SHARE_ARTWORK",
  SHARE_NEWS = "SHARE_NEWS",
  SHARE_POST = "SHARE_POST",
  START_DISCUSSION = "START_DISCUSSION",
  INVITE_FRIEND = "INVITE_FRIEND",
}

interface QuestDto {
  id: string;
  title: string;
  description: string;
  points: number;
  type: QuestType;
  color?: string;
  isLocked?: boolean;
  link?: string;
  shareMessage?: string;
  recurring: boolean;
  recurringInterval: QuestRecurringInterval;
}

interface CompletedQuestDto extends QuestDto {
  completedAt?: number;
  completedCount?: number;
  user?: User;
}

export enum QuestRecurringInterval {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export type { CompletedQuestDto, QuestDto };
