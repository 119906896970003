import { DEFAULT_PAGE_SIZE } from "@/shared/constants";
import config from "@shared/config/client";
import queryString from "query-string";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import { SortType, User, GetFollowsQuery } from "./types";

const me = (request: ICallableRequestBuilder<User | null>) => async () => {
  try {
    return await request.call(`${config.apiUrl}/users/me`);
  } catch {
    return null;
  }
};

const getFeaturedArtists =
  (request: ICallableRequestBuilder<User[]>) => (count: number) => {
    return request.call(
      `${config.apiUrl}/users/featured-artists?take=${count}`,
    );
  };

const getArtists =
  (request: ICallableRequestBuilder<User[]>) =>
  (
    sortType: SortType,
    skip: any,
    take: number = DEFAULT_PAGE_SIZE,
    showOpenToWorkOnly: boolean = false,
    searchTerm: string = "",
  ) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/users/artists`,
      query: {
        sortType,
        skip,
        take,
        showOpenToWorkOnly,
        searchTerm,
      },
    });

    return request.call(url);
  };

const getUserByIdentifier =
  (request: ICallableRequestBuilder<User>) => (id: string) => {
    return request.call(`${config.apiUrl}/users/${id}`);
  };

const getUsersByIdentifiers =
  (request: ICallableRequestBuilder<User[]>) => (adresses: string[]) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/users/multiple-by-identifier`,
      query: {
        identifiers: adresses,
      },
    });

    return request.call(url);
  };

const getFollowingById =
  (request: ICallableRequestBuilder<User>) =>
  ({ userId, page = 0, pageSize = DEFAULT_PAGE_SIZE }: GetFollowsQuery) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/users/${userId}/following`,
      query: {
        skip: page * pageSize,
        take: pageSize,
      },
    });

    return request.call(url);
  };

const getFollowersById =
  (request: ICallableRequestBuilder<User>) =>
  ({ userId, page = 0, pageSize = DEFAULT_PAGE_SIZE }: GetFollowsQuery) => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/users/${userId}/followers`,
      query: {
        skip: page * pageSize,
        take: pageSize,
      },
    });

    return request.call(url);
  };

const sendVerificationEmail =
  (request: ICallableRequestBuilder<User>) => (userId: string) => {
    return request.call(
      `${config.apiUrl}/users/send-verification/${userId}`,
      (init) => ({
        ...init,
        method: "POST",
      }),
    );
  };

const requests = {
  me,
  getFeaturedArtists,
  getArtists,
  getUserByIdentifier,
  getUsersByIdentifiers,
  getFollowingById,
  getFollowersById,
  sendVerificationEmail,
};

export default requests;
