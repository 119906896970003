import {
  bgClasses,
  iconHoverClasses,
  textClasses,
  textHoverClasses,
  questWrapperClasses,
  questBorderClasses,
  iconClasses,
} from "./constants";
import TwitterQuestIcon from "@/assets/icons/quests/twitter.svg";
import InstragramQuestIcon from "@/assets/icons/quests/instagram-quest.svg";
import TelegramQuestIcon from "@/assets/icons/quests/telegram.svg";
import ShareUpdateQuestIcon from "@/assets/icons/quests/share-update.svg";
import CoverImageQuestIcon from "@/assets/icons/quests/cover-image.svg";
import FollowArtistQuestIcon from "@/assets/icons/quests/follow-artist.svg";
import FillSocialsQuestIcon from "@/assets/icons/quests/fill-social-networks.svg";
import PublicProfileQuestIcon from "@/assets/icons/quests/public-profile.svg";
import UsernameQuestIcon from "@/assets/icons/quests/username.svg";
import ProfileImageQuestIcon from "@/assets/icons/quests/profile-image.svg";
import AddShortBioIconWhite from "@/assets/icons/add-short-bio.svg";
import SoulboundTokenIcon from "@/assets/icons/quests/soulbound-token.svg";
import LeaveCommentQuestIcon from "@/assets/icons/quests/leave-comment.svg";
import ParticipateForumDiscussionQuestIcon from "@/assets/icons/quests/participate-foum-discussion.svg";
import LikeArtworkQuestIcon from "@/assets/icons/quests/like-artwork.svg";
import ZealeyQuestIcon from "@/assets/icons/quests/zealey.svg";
import StartDiscussionQuestIcon from "@/assets/icons/quests/start-discussion.svg";
import ShareNewsQuestIcon from "@/assets/icons/quests/share-news.svg";
import ShareArtworkQuestIcon from "@/assets/icons/quests/share-artwork.svg";
import SharePostQuestIcon from "@/assets/icons/quests/share-post.svg";
import { twMerge } from "tailwind-merge";
import { QuestRecurringInterval, QuestType } from "@/api/quests/types";

const getClasses = (color: string) => {
  const bgClass = bgClasses[color];
  const iconHoverClass = iconHoverClasses[color];
  const textHoverClass = textHoverClasses[color];
  const textClass = textClasses[color];
  const questWrapperClass = questWrapperClasses[color];
  const questBorderClass = questBorderClasses[color];
  const iconClass = iconClasses[color];

  return {
    bgClass,
    iconHoverClass,
    textHoverClass,
    textClass,
    questWrapperClass,
    questBorderClass,
    iconClass,
  };
};

const getQuestIcons = (
  questType: QuestType,
  color: string,
  className = "",
  hasWhiteBackground = false,
) => {
  switch (questType) {
    case QuestType.TWITTER_SHARE:
    case QuestType.TWITTER_SHARE_NEWS:
    case QuestType.TWITTER_FOLLOW:
      return {
        icon: (
          <TwitterQuestIcon
            className={twMerge(
              `w-[270px] h-[270px] fill-${color} ml-2 ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <TwitterQuestIcon className="fill-white w-[180px] h-[194px] ml-2" />
        ),
      };
    case QuestType.INSTAGRAM:
      return {
        icon: (
          <InstragramQuestIcon
            className={twMerge(
              `w-[260px] h-[220px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <InstragramQuestIcon className="fill-white w-[215px] h-[180px]" />
        ),
      };
    case QuestType.TELEGRAM_JOIN:
      return {
        icon: (
          <TelegramQuestIcon
            className={twMerge(
              `w-[260px] h-[260px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <TelegramQuestIcon className="fill-white w-[180px] h-[184px]" />
        ),
      };
    case QuestType.SHARE_UPDATE:
      return {
        icon: (
          <ShareUpdateQuestIcon
            className={twMerge(
              `w-[260px] h-[260px] fill-${color}  ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <ShareUpdateQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    // TODO => add icon
    case QuestType.INVITE_FRIEND:
    case QuestType.FOLLOW_ARTIST:
      return {
        icon: (
          <FollowArtistQuestIcon
            className={twMerge(
              `w-[260px] h-[260px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <FollowArtistQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.CONNECT_SOCIAL_MEDIA:
      return {
        icon: (
          <FillSocialsQuestIcon
            className={twMerge(
              `w-[260px] h-[260px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <FillSocialsQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.COVER_IMAGE:
      return {
        icon: (
          <CoverImageQuestIcon
            className={twMerge(
              `w-[260px] h-[260px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <CoverImageQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.PUBLIC_PROFILE:
      return {
        icon: (
          <PublicProfileQuestIcon
            className={twMerge(
              `w-[260px] h-[260px] fill-${color}  ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <PublicProfileQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.PROFILE_IMAGE:
      return {
        icon: (
          <ProfileImageQuestIcon
            className={twMerge(
              `w-[260px] h-[260px] fill-${color}  ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <ProfileImageQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.USERNAME:
      return {
        icon: (
          <UsernameQuestIcon
            className={twMerge(
              `w-[260px] h-[204px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <UsernameQuestIcon className="fill-white w-[200px] h-[157px]" />
        ),
      };
    case QuestType.SOULBOUND_TOKEN:
      return {
        icon: (
          <SoulboundTokenIcon
            className={twMerge(
              `w-[260px] h-[260px] fill-${color}  ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <SoulboundTokenIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.ARTWORK_COMMENT:
      return {
        icon: (
          <LeaveCommentQuestIcon
            className={twMerge(
              `fill-${color}  mr-2 w-[270px] h-[278px] ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <LeaveCommentQuestIcon className="fill-white mr-2 w-[178px] h-[185px]" />
        ),
      };
    case QuestType.ARTWORK_LIKE:
      return {
        icon: (
          <LikeArtworkQuestIcon
            className={twMerge(
              `fill-${color} w-[270px] h-[278px] ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <LikeArtworkQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.PARTICIPATE_FORUM_DICSUSSION:
      return {
        icon: (
          <ParticipateForumDiscussionQuestIcon
            className={twMerge(
              `fill-${color} w-[276px] h-[280px] ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <ParticipateForumDiscussionQuestIcon className="fill-white w-[180px] h-[197px]" />
        ),
      };
    case QuestType.CLICK_LINK:
      return {
        icon: (
          <ZealeyQuestIcon
            className={twMerge(
              `fill-${color}  w-[280px] h-[276px] ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <ZealeyQuestIcon className="fill-white w-[180px] h-[175px]" />
        ),
      };
    case QuestType.SHORT_BIO:
      return {
        icon: (
          <AddShortBioIconWhite
            className={twMerge(
              `w-[260px] h-[260px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <AddShortBioIconWhite className={`fill-white w-[180px] h-[180px]`} />
        ),
      };
    case QuestType.SHARE_ARTWORK:
      return {
        icon: (
          <ShareArtworkQuestIcon
            className={twMerge(
              `fill-${color} w-[270px] h-[280px] ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <ShareArtworkQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.SHARE_NEWS:
      return {
        icon: (
          <ShareNewsQuestIcon
            className={twMerge(
              `w-[270px] h-[225px] fill-${color}  ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <ShareNewsQuestIcon className="fill-white w-[185px] h-[150px]" />
        ),
      };
    case QuestType.SHARE_POST:
      return {
        icon: (
          <SharePostQuestIcon
            className={twMerge(
              `w-[270px] h-[270px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <SharePostQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
    case QuestType.START_DISCUSSION:
      return {
        icon: (
          <StartDiscussionQuestIcon
            className={twMerge(
              `w-[280px] h-[280px] fill-${color} ${className}`,
              !hasWhiteBackground && "endemic:fill-black",
            )}
          />
        ),
        smallIcon: (
          <StartDiscussionQuestIcon className="fill-white w-[180px] h-[180px]" />
        ),
      };
  }
};

const getRecurringTooltipMsg = (
  reccuringInterval: QuestRecurringInterval,
  completedCount: number,
  completed: boolean,
) => {
  const recurringIntervalMsg = getRecurringReadableInterval(reccuringInterval);

  const recurringReadableCount = getRecurringReadableCount(completedCount);

  return `This is ${recurringIntervalMsg} quest and you have already completed it ${recurringReadableCount}.${!completed ? " Complete it again to get more points." : ""}`;
};

const getRecurringReadableInterval = (
  reccuringInterval: QuestRecurringInterval,
) => {
  switch (reccuringInterval) {
    case QuestRecurringInterval.DAY:
      return "daily";
    case QuestRecurringInterval.WEEK:
      return "weekly";
    case QuestRecurringInterval.MONTH:
      return "monthly";
  }
};

const getRecurringReadableCount = (completedCount: number) => {
  switch (completedCount) {
    case 1:
      return "once";
    case 2:
      return "twice";
    default:
      return `${completedCount} times`;
  }
};

export { getClasses, getQuestIcons, getRecurringTooltipMsg };
