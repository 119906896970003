import config from "@shared/config/client";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import { withAuthenticatedClientRequest } from "../requestBuilder/withClientRequest";

const claimIdenityToken = async (
  userAddress: string,
  signature: string,
): Promise<{ jobId: string }> => {
  const response = await fetch(
    `${config.mintdash.baseURI}/api/claim/drop/public/${config.mintdash.endemicIdentitySoulboundAddress}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-tenant": "endemic",
      },
      body: JSON.stringify({
        quantity: "1",
        walletAddress: userAddress,
        chainName: config.mintdash.endemicIdentitySoulboundNetwork,
        signature: signature,
      }),
    },
  );

  return await response.json();
};

const checkClaimStatus = async (jobId: number): Promise<{ state: string }> => {
  const resp = await fetch(
    `${config.mintdash.baseURI}/api/claim/drop/status/${jobId}`,
  );

  return await resp.json();
};

const mint =
  (request: ICallableRequestBuilder<any>) => async (soulboundToken: any) => {
    return request.call(
      `${config.apiUrl}/mintdash/endemic-identity/webhook/mint`,
      (init) => ({
        ...init,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event: "mint",
          chainId: config.mintdash.chainId,
          collectionId: config.mintdash.collectionId,
          contractAddress: config.mintdash.endemicIdentitySoulboundAddress,
          owner: soulboundToken.owner,
          nfts: soulboundToken.nfts,
        }),
      }),
    );
  };

const getListOfCollectionNfts = async (ownerAddress: string): Promise<any> => {
  const resp = await fetch(
    `${config.mintdash.baseURI}/api/nfts/owned-nfts/${config.mintdash.collectionId}?chainId=${config.mintdash.chainId}&ownerAddress=${ownerAddress}`,
  );

  return await resp.json();
};

const clientRequests = {
  claimIdenityToken,
  checkClaimStatus,
  getListOfCollectionNfts,
  mint: withAuthenticatedClientRequest(mint),
};

export default clientRequests;
