import { ReactNode, useState } from "react";
import DynamicLinkWrapper from "@/app/(components)/Basic/DynamicLinkWrapper";
import { getClasses } from "../helpers";
import { useQueryClient } from "@tanstack/react-query";
import useMe from "@/shared/hooks/useMe";
import { QuestDto, QuestType } from "@/api/quests/types";
import ReferralModal from "../../User/ReferralModal/ReferralModal";
import { twMerge } from "tailwind-merge";
import questsApi from "@/api/quests/client";
import { QuestLinkType } from "../types";
import { ClaimTokenModal } from "../../EndemicToken/ClaimTokenModal";
import { Modal } from "@/app/(components)/Basic/Modal";

export interface Props {
  children: ReactNode;
  quest: QuestDto;
  disableLink?: boolean;
  overrideDefaultClass?: boolean;
  onClick?: () => void;
}

const QuestLink = ({
  children,
  quest,
  disableLink,
  overrideDefaultClass,
  onClick,
}: Props) => {
  const queryClient = useQueryClient();

  const { me } = useMe();

  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);

  const [isClaimTokenModalOpen, setIsClaimTokenModalOpen] = useState(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const getQuestLink = (quest: QuestDto): QuestLinkType => {
    switch (quest.type) {
      case QuestType.TWITTER_SHARE:
        return me?.twitter
          ? {
              href: `https://twitter.com/intent/post?text=${encodeURIComponent(quest.shareMessage!)}`,
              linkType: "a",
              target: "_blank",
            }
          : {
              href: "/settings#social-links",
            };
      case QuestType.TWITTER_SHARE_NEWS:
        return { href: `/media` };
      case QuestType.TWITTER_FOLLOW:
        return me?.twitter
          ? {
              href: "https://twitter.com/intent/follow?screen_name=Endemic_nft",
              linkType: "a",
              target: "_blank",
              clickHandler: questsApi.completeFollowTwitterQuest,
            }
          : {
              href: "/settings#social-links",
            };
      case QuestType.TELEGRAM_JOIN:
        return me?.telegram
          ? {
              href: "https://t.me/endemicapp",
              linkType: "a",
              target: "_blank",
              clickHandler: questsApi.checkTelegramJoinQuest,
            }
          : {
              href: "/settings#social-links",
            };
      case QuestType.FOLLOW_ARTIST:
        return { href: "/artists" };
      case QuestType.CONNECT_SOCIAL_MEDIA:
        return me?.twitter && me?.telegram
          ? {
              href: "/quests",
              clickHandler: () => questsApi.completeQuest(quest.id),
            }
          : { href: "/settings#social-links" };
      case QuestType.COVER_IMAGE:
        return me?.headerImageUrl
          ? {
              href: "/quests",
              clickHandler: () => questsApi.completeQuest(quest.id),
            }
          : { href: "/settings#cover-image" };
      case QuestType.PUBLIC_PROFILE:
        return !me?.hideProfile
          ? {
              href: "/quests",
              clickHandler: () => questsApi.completeQuest(quest.id),
            }
          : {
              href: "/settings#make-public",
            };
      case QuestType.PROFILE_IMAGE:
        return me?.imageUrl
          ? {
              href: "/quests",
              clickHandler: () => questsApi.completeQuest(quest.id),
            }
          : { href: "/settings#profile-image" };
      case QuestType.USERNAME:
        return me?.displayName
          ? {
              href: "/quests",
              clickHandler: () => questsApi.completeQuest(quest.id),
            }
          : {
              href: "/settings#personal-info",
            };
      case QuestType.SOULBOUND_TOKEN:
        return {
          clickHandler: () => setIsClaimTokenModalOpen(true),
          href: "/quests",
        };
      case QuestType.SHORT_BIO:
        return me?.description
          ? {
              href: "/quests",
              clickHandler: () => questsApi.completeQuest(quest.id),
            }
          : {
              href: "/settings#personal-info",
            };
      case QuestType.PARTICIPATE_FORUM_DICSUSSION:
      case QuestType.START_DISCUSSION:
        return {
          href: "/forum",
        };
      case QuestType.ARTWORK_LIKE:
      case QuestType.ARTWORK_COMMENT:
      case QuestType.SHARE_ARTWORK:
        return {
          href: "/artwork",
        };
      case QuestType.CLICK_LINK:
        return {
          href: quest.link!,
          linkType: "a",
          target: "_blank",
          clickHandler: () => questsApi.completeQuest(quest.id),
        };
      case QuestType.SHARE_NEWS:
        return {
          href: "/media",
        };

      case QuestType.SHARE_POST:
        return {
          href: "/feed/global",
        };
      case QuestType.INVITE_FRIEND:
        return {
          clickHandler: () => setIsReferralModalOpen(true),
          href: "/quests",
        };
      case QuestType.SHARE_UPDATE:
      default:
        return { href: "/quests" };
    }
  };

  const { href, target, clickHandler, linkType } = getQuestLink(quest);

  const { questWrapperClass } = getClasses(
    quest.isLocked ? "gray" : quest.color!,
  );

  const onClaimTokenComplete = async () => {
    setIsSuccessModalOpen(true);
    setIsClaimTokenModalOpen(false);
  };

  return (
    <>
      {me?.address && (
        <ReferralModal
          isOpen={isReferralModalOpen}
          setIsOpen={setIsReferralModalOpen}
          referralCode={me.address}
        />
      )}
      <Modal
        isOpen={isSuccessModalOpen}
        hasCloseButton={true}
        onClose={() => setIsSuccessModalOpen(false)}
        className="lg:min-w-[500px] p-10"
      >
        <div className="flex justify-center text-lg">
          Your identity token has been claimed.
        </div>
      </Modal>
      {isClaimTokenModalOpen && (
        <ClaimTokenModal
          onSuccess={onClaimTokenComplete}
          onClose={() => {
            setIsClaimTokenModalOpen(false);
          }}
        />
      )}
      <DynamicLinkWrapper
        componentType={linkType}
        wrapWithLink={!disableLink}
        href={href}
        target={target}
        onClick={async () => {
          onClick && onClick();
          if (clickHandler) {
            await clickHandler();
            queryClient.invalidateQueries(["completed-quests"]);
            queryClient.invalidateQueries(["uncompleted-quests"]);
          }
        }}
        className={twMerge(
          !overrideDefaultClass &&
            "group h-[520px] w-full  rounded-[40px] border flex flex-col items-center p-6  transition-all duration-500 endemic:bg-white endemic:hover:bg-black  endemic:border-transparent xl:mb-15 mb-5 lg:min-w-[325px]",
          quest.isLocked
            ? "pointer-events-none cursor-not-allowed"
            : questWrapperClass,
        )}
      >
        {children}
      </DynamicLinkWrapper>
    </>
  );
};

export default QuestLink;
