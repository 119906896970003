import { ClientRequestBuilder } from "./clientRequestBuilder";
import { ICallableRequestBuilder } from "./types";

type WithLastSignal<T extends unknown[]> = [...T, AbortSignal?];

const exportSignal = <T extends unknown[]>(args: WithLastSignal<T>) => {
  let signal: AbortSignal | undefined = undefined;

  if (args[args.length - 1] instanceof AbortSignal) {
    signal = args.pop() as AbortSignal;
  }

  return { signal, argsWithoutSignal: args as unknown as T };
};

export const withClientRequest = <TArgs extends unknown[], T>(
  callback: (
    request: ICallableRequestBuilder<T>,
  ) => (...args: TArgs) => Promise<T>,
): ((...args: WithLastSignal<TArgs>) => Promise<T>) => {
  return async (...args: WithLastSignal<TArgs>) => {
    const { signal, argsWithoutSignal } = exportSignal(args);

    const request = new ClientRequestBuilder<T>(undefined, signal);
    await request.chainify();

    return await callback(request)(...argsWithoutSignal);
  };
};

export const withAuthenticatedClientRequest = <TArgs extends unknown[], T>(
  callback: (
    request: ICallableRequestBuilder<T>,
  ) => (...args: TArgs) => Promise<T>,
): ((...args: WithLastSignal<TArgs>) => Promise<T>) => {
  return async (...args: WithLastSignal<TArgs>) => {
    const { signal, argsWithoutSignal } = exportSignal(args);

    const request = new ClientRequestBuilder<T>(undefined, signal);
    await request.chainify();
    await request.authenticate();
    return await callback(request)(...argsWithoutSignal);
  };
};
